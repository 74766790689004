.nav-tabs h4 {
	text-transform: uppercase;
	font-weight: bold;
	float: left;
	margin-right: 60px;
}

.form-button-row {
	border-top: 0;

	.btn {
		text-transform: none;
	}

	&:last-child {
		border-top: 1px solid #ddd;
	}

	&.panel-heading,
	&.panel-footer {
		text-transform: uppercase;
		padding: 8px 15px 10px 15px !important;
	}
}
