@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	display: block;
	position: relative;
	overflow: hidden;
	background: black;
}

.thumbnail {
	width: 0;
	height: 0;
	background-size: contain ;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
