@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.vbBtn {
	display: inline-block;
	font-size: @type14;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	padding: 8px 12px;
	line-height: 1.4;
	position: relative;
	border: none;
	.RoundedShape(@defaultBorderRadius);

	&[disabled], &:global(.disabled) {
		opacity: .5;
		cursor: not-allowed;
		pointer-events: auto;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

	:global(.btn-group)>& {
		&:first-child:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.btnPrimary {
	background-color: var(--theme-accent) !important;
	color: var(--theme-accent-txt) !important;

	&:hover:not([disabled]):not(:global(.disabled)) {
		background-color: var(--theme-accent-darken-20) !important;
	}
}

.btnSecondary {
	background-color: var(--theme-primary-lighten-10) !important;
	color: var(--theme-primary-txt) !important;

	&:hover:not([disabled]):not(:global(.disabled)) {
		background-color: var(--theme-primary-darken-20) !important;
	}

	&:global(.btnSecondaryInactive) {
		background-color: var(--theme-primary-darken-10) !important;
	}
}

.btnWhite {
	background: @color2Lighten20;
	color: @color1 !important;
	border: 1px solid @borderColor;

	&:hover:not([disabled]):not(:global(.disabled)) {
		background-color: @color2Darken15 !important;
	}

	:global(.glyphicons) {
		color: inherit !important;
	}
}