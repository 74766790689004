@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@pulseTime: 2s;
@spinTime: (4 / 7 * @pulseTime);
@pulseStart: 30deg;
@pulseEnd: 310deg;
@radius: 42;
@circumfrence: 2 * pi() * @radius;

.root {
	display: inline-block;
}

.spinner {
	display: inline-block;

	.setPulse(@angle) {
		stroke-dashoffset: (@circumfrence * (1 + @angle / 360deg));
		transform: rotate((@angle / 2));
	}

	svg{
		width: 23px;
		height: 23px;
		stroke-dasharray: @circumfrence;
		stroke-linecap: round;

		circle {

			fill: none;
			stroke-width: 5px;
			&.pip {
				stroke: var(--theme-accent);
			}
		}

		.complete& {
			.setPulse(360deg);
		}

		.inactive& {
			.setPulse(0deg);
		}

		.xsmall& {
			width: 12px;
			height: 12px;
		}

		.medium& {
			width: 58px;
			height: 58px;
		}

		.large& {
			width: 88px;
			height: 88px;
		}
	}


	&.active {
		animation: spin @spinTime linear infinite;

		svg {
			.setPulse(@pulseStart);
			animation: pulse @pulseTime ease-in-out infinite;
		}
	}

	@keyframes pulse {
		0%{
			.setPulse(@pulseStart);
		}

		50%{
			.setPulse(@pulseEnd);
		}

	}

	@keyframes spin {
		0%{
			transform: rotate(0);
		}
		100%{
			transform: rotate(360deg);
		}
	}
}
