@import "global/variables";
@import "global/mixins";
@panelHeadingHeight: 190px;
@headerColPadding: 0 32px 0 8px;


.user-list {
	font-size: @type12;

	.primary-cell {
		a.item-icon-label {
			width: 32px;
			min-width: 32px;

			&.new-badge {
				width: auto;
			}
		}

		.circle {
			background: @color2Fade50;
			height: 32px;
			width: 32px;
			padding-top: 8px;
			color: @color1Fade50;
			position: relative;
			display: inline-block;
			left: 0;
			top: 0;
			margin-left: 0;
		}

		.badge {
			background: @accentColor;
			color: @white;
			font-size: @type8;
			.RoundedShape(2px);
			padding: 4px;
			width: 32px;
			text-transform: uppercase;
		}

		.new-badge .badge {
			width: auto;
		}
	}

	.no-licenses {
		background: @warningColor !important;
		color: @white;
		z-index: 10;
		border-color: transparent;
		position: absolute;
		height: 28px;
		line-height: 28px;
		padding: 0 4px;
		top: 8px;
		right: 50px;

		.BoxShadow(0 0 8px 4px @blackFade25);
		.RoundedShape(4px);

		.glyphicons {
			margin-right: 4px;
			color: @whiteFade75;
		}
	}

	.license-status {

		font-size: @type14;

		@media(max-width: (@viewPortSmallWidth - 1)) {
			text-align: right;
			font-size: @type12;
		}

		span:not(:last-child):after {
			content: '|';
			margin: 0 @typeUnit1-2x;
			color: @borderColorLight;
		}
	}

	.file-list {

		.file-list-header {
			padding: 0 16px !important;

			.file-list-header-col {

				&:first-child {
					padding-left: 16px;
				}

				@media (min-width: @viewPortSmallWidth) {
					padding: 0 10em 0 8px;

					&:last-child {
						padding: @headerColPadding;
					}
				}

				@media (min-width: @viewPortLargeWidth) {
					padding: @headerColPadding;

					&:last-child {
						padding: @headerColPadding;
					}
				}
			}
		}
	}
}

.btn-suspend.active,
.btn-suspend:active {
	background-color: @warningColor;
	color: @whiteFade75;
	font-weight: 500;
}

.btn-spacer {
	margin-left: 8px;
}
