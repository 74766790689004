.inline-create-form {
	padding: 15px;

	.combined-toolbar > .table-cell {
		padding: 0;
		vertical-align: bottom;

		.form-control {
			.RoundedLeftShape(4px);
		}

		.btn {
			margin: 0;

			&.submitBtn {
				padding: 6px 24px;
				.RoundedRightShape(4px);
				height: 30px;
			}

			@media(min-width: 768px) {
				&.submitBtn {
					padding: 6px 48px;
				}
			}
		}
	}

	&.flat-list {
		padding-left: 15px;
	}

	.tree-object & {
		padding: 0 0 0 15px;
	}

	&.add-background {
		&,
		tr&:hover,
		tr:hover & {
			background: rgba(89,96,101,0.3) !important;
		}

		.data-table tr& th,
		.data-table tr& td {
			vertical-align: top !important;
		}

		.data-table tr&:hover th,
		.data-table tr&:hover td {
			background: none;
		}
	}

	td& .table-cell>div[class^='col-']{
		padding-left: 0;
	}

	.error-overlay {
		color: @warningColor;
		margin-top: 8px;
	}

	.form-control:focus {
		border-color: @accentColor !important;
	}

	&.vertical-align-top .combined-toolbar > .table-cell {
		vertical-align: top;
	}

	.alert-danger .glyphicons {
		margin-right: 1rem;
	}
}

.form-control.ng-invalid-unique-name {
	border-color: @warningColorFade50 !important;
}