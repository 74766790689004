@import "global/variables";
@import "global/mixins";

.caret {
	display: inline-block;
	height: 0;
	width: 0;
	vertical-align: middle;

	&.closed {
		margin-left: 8px;
		.TriangleRight(6px, fade(@black, 35%));
	}

	&.expanded {
		margin-left: 4px;
		.TriangleBottom(6px, @color1LightText);

	}

	.down &,
	&.down {
		.TriangleBottom(4px, @color1LightText);
	}

	.up &,
	&.up {
		.TriangleTop(4px, @color1LightText);
	}

	.btn &.down {
		margin-left: 4px;
		border-width: 6px 6px 0 6px;
	}

	.btn &.up {
		margin-left: 4px;
		border-width: 0 6px 6px 6px;
	}

	.btn.btn-sm &.down {
		border-width: 4px 4px 0 4px;
	}

	.btn.btn-sm &.up {
		border-width: 0 4px 4px 4px;
	}

	.dropdown-toggle & {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 4px;
		vertical-align: middle;
		border-top: 6px solid @white;
		border-bottom: 0px solid @white;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
	}

	.admin .dropdown-toggle & {
		border-top-color: @whiteFade50;
		border-bottom-color: @whiteFade50;
	}

	.dropup .dropdown-toggle & {
		border-top-width: 0px;
		border-bottom-width: 6px;
	}
}
