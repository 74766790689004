@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vbrick-player-wmv {
	.player-placeholder,
	.vb-player {
		width: 100%;
		height: 100%;
	}

	.player-wrap {
		background: @black;
		height: 100%;
		width: 100%;

		.overlay-play {
			&:extend(.AbsoluteFill);
			cursor: pointer;
			z-index: 5;

			&:hover .circle {
				background: @accentColor;
				color: @white;
			}

			.circle {
				color: @whiteFade75;
				height: 50px;
				width: 50px;
				background: @color1Fade50;
				font-size: 30px;
				top: 50%;
				left: 50%;
				position: absolute;
				margin-top: -25px;
				margin-left: -25px;
				z-index: 10;
			}

			.glyphicons {
				margin-top: 9px;
				margin-left: 2px;
			}

			.vb-player-ie & {
				z-index: 2;
			}
		}

		.overlay-reconnecting {
			&:extend(.AbsoluteFill);
			background-color: @black;

			.status-msg h3 {
				color: @white;
			}
		}

		.fixed-ratio-wrapper {
			z-index: 1;
		}

		.vb-player-no-video-overlay & {
			.player-placeholder {
				padding-bottom: 35px;
			}

			&.hide-video object,
			.modal-open & object  { //vb plugin for WMP doesn't support content overlay, so hide the video object when necessary
				visibility: hidden;
			}
		}

		.modal-open .vb-player-ie & object {
			visibility: hidden;
		}

		.subtitles {
			position: absolute;
			bottom: 40px;
			color: @white;
			font-size: 18px;
			text-align: center;
			text-shadow: 1px 1px @black, -1px 1px @black, 1px -1px @black, -1px -1px @black; //simulates a stroke
			white-space: pre-line;
			width: 100%;
		}
	}

	.player-controls {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		opacity: 1;
		.TransitionAll(all linear 0.25s);
		border: 1px solid rgb(30,30,30);
		background-color: @color1;
		-webkit-transform: translateZ(0);

		/* TODO: button transitions cancel control bar leave transitions, find a workaround */
		.btn {
			.TransitionAll(none);

			.glyphicons,
			.subtitles-icon {
				vertical-align: middle;
			}

			&.selected {
				color: @accentColor;
			}
		}

		&-inner {
			width: 100%;
			display: table;
		}


		.table-cell {
			vertical-align: middle;

			&:first-child {
				padding-right: 10px;
			}
		}

		.flex-container {
			.alignItems(center);

			.video-length {
				line-height: 36px;
			}

			.time-label {
				bottom: 24px;
			}
		}

		.dropdown {
			&.open {
				.btn {
					background: @accentColor;
					color: @white;
				}

				.dropdown-menu {
					background-color: @color1;

					> li > a {
						color: @whiteFade50;
						padding-left: 30px;

						&:hover {
							color: @white;
							background-color: @accentColor;
						}

						&.selected:before {
							.GlyphiconFont;
							content: '\E207';
							position: absolute;
							left: 10px;
						}
					}
				}
			}
		}

		.vb-player-ie & {
			position: absolute;

			.time-label {
				bottom: 24px;
			}
		}

		.vb-player-no-video-overlay & {
			.time-label {
				display:  none;
			}
		}

		.subtitles-icon {
			.RoundedShape(6px);
			padding: 0 2px;
			border-width: 2px;
			border-style: solid;
		}

		.glyphicons.cog {
			font-size: @type16;
			top: 0;
		}
	}

	.icon-button {
		background: none;
		color: @whiteFade50;
		border: none;
		.RoundedShape(0);
		vertical-align: middle;
		.BoxShadow(none);
		text-align: center;

		&:hover,
		&:focus,
		&:focus:active,
		&:active {
			.RoundedShape(0);
			color: @white;
			text-decoration: none;
		}

		.glyphicons {
			font-size: 18px;
			line-height: 1;
			top: 0;
			float: left;
		}
	}

	.play-pause-btn {
		padding: 0 16px;
		text-align: center;
		width: 50px;
		height: 30px;

		&:hover {
			background: @accentColor;
			color: @white;
		}
	}

	.progress-indicator,
	.progress-indicator-volume {
		position: relative;
		cursor: pointer;
		height: 12px;
		width: 100%;
		background: rgb(90,90,90);
	}

	.time-label {
		position: absolute;
		bottom: 16px;
		background: @backgroundLighten03;
		.RoundedShape(2px);
		padding: 2px 5px 1px 5px;
		color: @color1;
		.Transform(translate(-50%, 0));
		border: 1px solid @color1Fade75;
		line-height: 1.5;

		&:after {
			content: '';
			width: 0;
			height: 0;
			.TriangleBottom(4px, @backgroundLighten03);
			position: absolute;
			left: 50%;
			margin-left: -4px;
			bottom: -4px;
		}
	}

	.video-length {
		white-space: nowrap;
		color: @whiteFade50;
		display: table-cell;
		font-size: 12px;
		padding: 0 8px;
		line-height: 0;
		vertical-align: middle;

		&.video-length-live {
			padding: 0;
		}
	}

	.control-spacer {
		width: 18px;
	}

	.volume-btn {
		width: 30px;
		margin-right: 10px;
	}

	.video-slider-bar,
	.volume-slider-bar {
		padding: 7px 0;
		position: relative;
	}

	.volume-slider-bar {
		width: 80px;
		padding-right: 10px;
	}

	.table-cell:last-child .closed-caption-icon {
		margin-right: 5px;
	}

	.full-screen .btn {
		width: 45px;
	}

	/* jquery slider Overrides */
	.ui {
		&-slider-range {
			.TransitionAll(all linear 0.15s);
			outline: 1px solid transparent; /* fix trailing lines in chrome http://stackoverflow.com/questions/12348884/jquery-ui-draggable-element-leaves-weird-traces-in-chrome */
		}

		.progress-indicator-volume &-widget-content{
			background-color: @accentColor;
		}

		&-widget-header {
			border: none;
			background: @accentColor;
		}

		&-slider .ui-slider-handle {
			display: none;
		}

		&-widget-content {
			border: none;
		}

		&-corner {
			&-all,
			&-bottom,
			&-right,
			&-br {
				.BorderBottomRightRadius(0);
			}

			&-all,
			&-bottom,
			&-left,
			&-bl {
				.BorderBottomLeftRadius(0);
			}

			&-all,
			&-top,
			&-right,
			&-tr {
				.BorderTopRightRadius(0);
			}

			&-all,
			&-top,
			&-left,
			&-tl {
				.BorderTopLeftRadius(0);
			}
		}
	}

	@media(min-width: 1400px) {
		.player-wrap .overlay-play {
			.circle {
				height: 80px;
				width: 80px;
				font-size: 48px;
				margin-top: -40px;
				margin-left: -40px;
			}

			.glyphicons {
				margin-top: 14px;
				margin-left: 3px;
			}
		}
	}

	@media(min-width: @viewPortMedWidth) {
		.vbrick-modal-player .player-controls {
			//position: relative;
		}

		.full-screen-video {
			.container-fluid {
				padding: 0 16px;
			}

			.vbrick-modal-player .player-controls {
				position: absolute;
			}

			.normal-target {
				display: none;
			}

			.player-wrap:not(.vbrick-modal-player) .player-controls {
				left: 30px;
				right: 30px;
				bottom: 16px;
			}
		}
	}
}
