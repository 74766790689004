@import "../global/variables";
@import "../global/mixins";

.carousel {
	.fixed-ratio-wrapper img {
		box-shadow: none;
	}

	// text-center because angular-ui stupidly has it as part of the template
	&-inner>.item.text-center {
		// line-height: 0;
		text-align: left;
	}

	@carousel-control-color: @blackFade25;
	&-control {
		width: 48px;
		font-size: 30px;
		color: @carousel-control-color;
		text-align: center;
		border: none;

		z-index: 10;
		text-shadow: none;
		background: none;
		opacity: 1;

		&.left {
			background: none;
			left: 0;
			.RoundedRightShape(4px);
			filter: none; // needed for IE

			.glyphicons {
				margin-left: -16px;
			}
		}

		&.right {
			background: none;
			right: 0;
			.RoundedLeftShape(4px);
			filter: none; // needed for IE

			.glyphicons {
				margin-right: -16px;
			}
		}

		&:hover {
			color: @accentColor;
			opacity: 1;
			text-shadow: none;
		}

		&:focus {
			color: @carousel-control-color;
		}

		.glyphicons {
			position: relative;
			vertical-align: middle;
			top: 0.6em;
		}

		.disabled {
			display: none;
		}

		.icon-next {
			right: 0;
		}

		.icon-prev {
			left: 0;
		}

		.icon-next,
		.icon-prev {
			margin-left: 0;
			margin-right: 0;
			width: 60px;

			&:before {
				content: '\e224';
				font: 20px @glyphiconFont;
				position: relative;
			}
		}

		.icon-prev:before {
			content: '\e225';
		}
	}

	&-indicators {
		display: block;
		bottom: -44px;

		li {
			margin: 1px 3px;
			border: none;
			background: @blackFade25;
		}

		.active {
			width: 10px;
			height: 10px;
			margin: 1px 3px;
			background-color: @accentColor;
			border: none;
		}
	}

	/* Needed until backend can fix proper cropping of thumbnails to same size */
	&-thumbnail-wrapper {
		background-color: @black;
	}

	@media(min-width: 768px){

		&-control {
			width: 60px;
		}

		&-control .icon-prev,
		&-control .icon-next {
			height: 40px;
			margin-top: -20px;
			width: 60px;
		}

		&-indicators {
			bottom: -28px;
			li {
				background: @blackFade50;
			}
		}
	}
}
