@import "../global/variables";
@import "../global/mixins";

.tile-item {
	margin-bottom: 30px;
	background: @white;
	border:1px solid @borderColor;
	.RoundedShape(2px);
	position: relative;
	top: 0;

	&:hover {
		.PointerCursor();
		.TransitionAll(all linear 0.125s);
		border-color: @blackFade50;
		.BoxShadow(0 0 8px @blackFade50);

		.fixed-ratio-wrapper:first-child img {
			opacity: 0.85;
		}

		a:hover {
			text-decoration: none;
		}

		h2 a, h3 a {
			color: @accentColor;
		}
	}

	&.uploading,
	&.processing-error,
	&.inactive:not(.pending-approval),
	&.not-ready {
		a {
			color: @color1LightText;
		}

		.fixed-ratio-wrapper:first-child a.fixed-ratio {
			background: @color1LightText;

			img {
				opacity: 0.5;
			}
		}
	}

	&.not-ready,
	&.inactive:not(.pending-approval) {
		background: @backgroundLighten03;

		.tile-footer:before {
			.VerticalGradient(rgba(245, 245, 245, 0), @backgroundLighten03);
		}
	}

	p {
		color: @color1LightText;
	}

	.rating {
		margin-bottom: 1rem;
		font-size: 0.75rem;
	}

	.ratings-count {
		color: @color1LightText;
		font-size: @type14;
		margin-left: 2px;
	}

	.info-container {
		padding: 15px 15px 0 15px;

		h2, h3 {
			margin-top: @typeUnit1-2x;
		}

		> h2, > h3 {
			margin-top: 0;
		}

		.rating.float-right {
			// fixes flaky rendering
			position: absolute;
			top: 14px;
			right: 12px;
		}

	}


	h2, h3 {
		margin-bottom: @typeUnit1-2x;
		line-height: 1;

		a {
			&:extend(.HyphensAuto);
			&:extend(.WordBreak);
		}
	}



	h4 {
		color: @color1LightText;
	}

	.tile-footer {
		padding: 0 15px;
		color: @color1LightText;
		border-top: 1px solid @blackFade05;
		position: relative;
		height: 36px;
		line-height: 36px;
		background: @white;
		.RoundedBottomShape(1px);

		a {
			color: @color1LightText;
		}

		p {
			&:extend(.TextClip);
		}

		>span {
			&:extend(.TextClip);
			max-width: 40%;

			&:first-child {
				max-width: 55%;
			}
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: -21px;
			height: 20px;
			.VerticalGradient(fade(@white, 0%), @white);
		}

		&.category {
			border-top-color: transparent;
		}
	}

	.fixed-ratio-wrapper:first-child a.fixed-ratio {
		.RoundedTopShape(1px);
		background: @color1;
		/* this fixes the overflow:hidden in Chrome */
		-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

		> img {
			.RoundedTopShape(1px);
		}
	}

	.fixed-ratio-wrapper + .fixed-ratio-wrapper {
		margin-bottom: -36px; // this is tile-footer height, makes proportions 1:1 on tile
	}

	&.rejected .video-duration-overlay,
	.video-inactive .video-duration-overlay {
		opacity: 0.5;
	}

	.video-duration-overlay {
		background: @color1Fade75;
		top: 8px;
		right: @typeUnit1-4x;
	}
}

@media (max-width: 1199px) {
	.tile-grid {
		margin-right: -8px;
		margin-left: -8px;

		.grid-item-list div[class^="col-"] {
			padding: 2px;
			width: 50%;
			float: left;
		}

		.tile-item {
			margin-bottom: 16px;
		}
	}
}
