@import (reference) '~rev-less/global/variables';
@import (reference) "~rev-less/global/mixins";

.textArea {
	composes: TextInputFocusBorder from '~rev-shared/ui/text-input/vb-text-input.module.less';
	border: 1px solid @lightBorder;
	color: @primaryDark;
	width: 100%;
	height: var(--vb-ui-text-area-height, 200px);
	font-size: @type14;
	padding: 10px 16px;
	line-height: 1.3;
	background-color: @white;
	.RoundedShape(4px);

	&:global(.ng-invalid):not(:global(.ng-untouched)):not(:focus) {
		border: @invalidFieldBorder;
	}
}