.transcribeSelectBtn {
 	&:global(.btn.btn-transparent) {
		padding: 0 10px;
		text-decoration: underline;
		box-shadow: none;
	}
}

.transcribeLangMenu:global(.dropdown-menu) {
	max-width: 230px;
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
	background: var(--theme-primary) !important;
	border-radius: 0 !important;
	border: 1px solid var(--theme-primary-txt) !important;

	:global(.btn-transparent.dropdown-item.btn) {
		width: 100%;
		text-align: left;
		color: var(--theme-primary-txt);
		box-shadow: none;

		&:hover, &:focus {
			font-weight: bold;
			color: var(--theme-primary-txt);
		}
	}

}

.toggleLabel {
	position: relative;
	top: 3px;
}

.toggleIcon {
	position: relative;
	margin-right: 8px;
	top: 2px;
}

.notAvailableContainer {
	max-width: 250px;
	word-wrap: break-word;
}

.toggleBtnLabel {
	font-size: 14px;
	text-transform: initial;
}

.flexContainer {
	display: flex;
	align-items: baseline;
}

.flexColumn {
	composes: flexContainer;
	flex-flow: column;

	vb-toggle-button {
		label {
			margin-bottom: 12px !important;
		}

	}
}

.flexRow {
	composes: flexContainer;
	flex-flow: row;
}

.chevronIcon {
	margin-left: 6px;
}