@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

@mobileMaxWidth: @viewPortMedWidth - 1px;
@sidebarButtonContainerBorder: 1px solid @lightBorder;
@sidebarButtonHeight: 48px;
@sidebarButtonWidth: 48px;
@sidebarPanelBackgroundColor: @color1Lighten20;
@sidebarPanelTextColor: @color2Lighten04;
@sidebarPanelWidth: var(--sidebar-panel-width, 432px);

.root {
	overflow: hidden;
	position: relative; // for Mac Safari (content would produce html element overflow)

	&:global(.vbUiSidebarRootOpen) .sidebarButtonContainer {
		border-left: 1px solid var(--theme-primary-txt-fade-50);
	}
}

.sidebarButtonContainer {
	order: 2;
	border: 0;
	box-sizing: content-box;
	width: @sidebarButtonWidth;
	z-index: 1;

	&:not(:global(.noBorder)) {
		border-left-width: 1px;
		border-left-style: solid;
	}
}

.sidebarButtonContainerFixedColor {
	background: @color1Lighten05;
	border-left: @sidebarButtonContainerBorder !important;
}

.sidebarButton {
	composes: theme-accent-btn from global;
	background: transparent;
	border: 0;
	font-size: @type16;
	width: @sidebarButtonWidth;
	height: @sidebarButtonHeight;
	position: relative;

	&:not(:global(.noBorder)) {
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	&:hover {
		background: @hoverBackground;
	}

	.btnIcon {
		pointer-events: none;
	}
}

.sidebarButtonFixedColor {
	border-bottom: @sidebarButtonContainerBorder !important;
	color: @color2Lighten05;

	&:first-child {
		border-top-width: 0 !important;
	}
}

.sidebarPanel {
	order: 1;
	min-height: 1px; // for IE / Edge
	width: @sidebarPanelWidth;
	border-left-width: 1px;
	border-left-style: solid;

	&:not(:global(.open)) {
		width: 0;
		overflow-y: hidden; // legacy Edge workaround

		.sidebarPanelLayout {
			width: 0;
		}
	}
}

.transitionWidth {
	.TransitionAll(width cubic-bezier(0.22, 1, 0.36, 1) 0.5s);
}

.sidebarPanelFixedColor {
	background: @sidebarPanelBackgroundColor;
	color: @sidebarPanelTextColor;
}

.sidebarPanelLayout {
	height: 100%;
	overflow: hidden;
	position: absolute;
	width: @sidebarPanelWidth;
}

.header {
	composes: theme-accent theme-primary-font-border-fade-50 from global;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	font-size: @type16;
	line-height: @sidebarButtonHeight - 1px;
	padding: 0 1rem;
	width: @sidebarPanelWidth;
	.flexShrink(0);
}

.sidebarPanelBody {
	height: 100%;
	overflow-y: auto;
}

.notificationCount {
	border-radius: @defaultBorderRadius;
	padding: 3px;
	font-size: 0.625rem;
	min-width: 1.5em;
	line-height: 1.25em;
	position: absolute;
	right: 5px;
	bottom: 9px;
	border-width: 1px;
	border-style: solid;
	composes: theme-accent theme-accent-font-border from global;
}

.notificationIcon {
	composes: theme-accent theme-accent-font-border from global;
	position: absolute;
	top: 6px;
	right: 2px;
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;
	height: 20px;
	width: 20px;

	&:before {
		font-size: 10px;
	}
}

// mobile layout
.root:not(.mobileLayoutDisabled):not(.customMobileBreakPoint) {
	@media (max-width: @mobileMaxWidth) {
		.mobileLayout;
	}
}

// custom break point mobile layout
.root:not(.mobileLayoutDisabled) {
	&.customBreakPointReached {
		.mobileLayout;
	}
}

//mobileLayout
.mobileLayout {
	.header {
		width: 100%;
	}

	&:global(.vbUiSidebarRootOpen) {
		.flexItem(100%);
	}

	.sidebarButton {
		border-bottom: transparent;
	}

	.sidebarButtonContainer {
		border-left: transparent;
		.displayFlex();
		justify-content: space-around;
		width: 100%;

		@media (max-width: @mobileMaxWidth) {
			border-top-width: 1px;
			border-top-style: solid;
		}

	}

	.sidebarPanel {
		width: 100%;
		.TransitionAll(none);

		&:not(:global(.open)) {
			height: 0;
		}

		@media (max-width: @mobileMaxWidth) {
			border: 0;
		}
	}

	.sidebarPanelLayout {
		width: 100%;
	}

	.sidebarPanelBody {
		margin-bottom: @sidebarButtonHeight;
	}
}


.tooltipContainer {
	:global(.tooltip-arrow) {
		margin-left: 3px !important;
	}
}
