@import (reference) "~rev-less/global/variables";

.actionButton {
	composes: dropdown-toggle from global;
	padding: 4px 6px;
}
.menuWrapper {
	composes: dropdown from global;
	position: relative;
}

.menuItemWrapper {
	composes: dropdown-menu from global;
	position: absolute;
	background: @white;
	min-width: 7.5vw;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	left: -5vw;
	right: auto;
	top: -8px;
}

.menuItem {
	composes: dropdown-item from global;
	display: block;
	padding: 10px 15px;
	line-height: 1;

	&:hover, &:focus {
		font-weight: bold;
	}
}

.moreIcon {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 18px;
}

.themedWrapper {
	background: var(--theme-primary);

	.menuItem {
		background: none !important;
		color: var(--theme-primary-txt) !important;
	}
}
