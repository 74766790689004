@import "../global/variables";
@import "../global/mixins";

.form-control {
	.RoundedShape(2px);
	color: @color1;
	height: 31px;
	border: 1px solid @borderColor;
	.BoxShadow(inset 0 0 1px @borderColor);
	padding: 0 @typeUnit1-2x;

	&.active,
	&:active,
	.input-group-highlighted.active &,
	.has-success &:focus,
	.has-success &:active {
		color: @color1;
		border-color: @accentColor;
	}

	.has-success & {
		&:extend(.form-control);
	}

	.has-error &:focus {
		.BoxShadow(0 0 4px @blackFade25);
	}

	&[disabled],
	&[readonly] {
		cursor: not-allowed;
		border: none;
	}

	&[readonly][disable-password-autocomplete] {
		cursor: text;
		border: 1px solid @borderColor;
		background-color: @white;
	}

	&.input-lg {
		height: 46px;
		padding: 10px 16px;
		font-size: 18px;
		line-height: 1.33;
	}

	&select{
		background: @white;
		.BoxShadow(0 1px 1px @blackFade10);
	}

	&textarea {
		height: auto;
		resize: vertical;
	}
}

.has-success textarea.form-control {
	height: auto;
}

fieldset[disabled] .form-control
{
	cursor: not-allowed;
	border: none;
}

.media-edit-content {
	.form-control[disabled],
	.form-control[readonly],
	fieldset[disabled] .form-control
	{
		background-color: @blackFade05;
	}
}

.admin-content {
	.form-control {
		&:not([disabled]) {
			background-color: @backgroundLighten8;
		}

		&[disabled] {
			color: @blackFade50;
		}

		&:active,
		&:focus
		{
			background: @accentLighten55;
		}
	}

	.has-success .success-field {
		&:extend(.admin .required .required-field);

		&:before {
			content: "\E194";
			color: @accentColor;
			position: absolute;
			left: 0;
			top: 2px;
			display: inline-block;
			margin: 5px 5px 0 0;
			font: 13px/1em @glyphiconFont;
			font-style: normal;
			font-weight: 400;
			vertical-align: middle;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
		}
	}

	.form-inline .has-success .success-field:before {
		top: -5px;
		left: 4px;
	}


	.required .required-field
	{
		position: absolute;
		top: 3px;
		right: 3px;

		&:before {
			font-size: @type24;
		}
	}
	.has-success .success-field,
	.has-error .error-field
	{
		position: absolute;
		top: 3px;
		right: 3px;
	}
	select.form-control {
		.VerticalGradient(@white, @adminPanel);
		.BoxShadow(0 1px 2px @blackFade10);

		&.ng-invalid.ng-dirty {
			border: 1px solid @warningColorFade50;
			color: @warningColor;
		}

		&:focus::-ms-value { //IE10+ default styling override to prevent the blue highlighting after making a selection
			background-color: transparent;
			color: inherit;
		}
	}

	@media(min-width: 768px){
		.has-error .error-field {
			margin-left: -18px;
			right: 20px;
		}
	}
}
.form-control[disabled],
.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder,
.form-control::-moz-placeholder,
::-moz-placeholder,  /* Firefox 19+ */
.form-control:-ms-input-placeholder,
:-ms-input-placeholder
{
	color: @gray50;
}

/* hide default x in IE inputs */
::-ms-clear {
	width : 0;
	height: 0;
}

button {
	color: inherit;
}
