@import (reference) "~rev-less/global/variables";
.chkBox {
	padding-left: 5px;

	button {
		height: 21px;
		width: 21px;
		border-radius: 2px;
		border-width: 3px;
	}
}

.notThemed {
	button:global(.active) {
		background-color: @accentColor !important; //hardcoded for now.
		color: @white !important;
	}
}

.themed {
	button {
		border-color: var(--theme-primary-txt);
	}
}
