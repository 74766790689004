@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.btnToggleWrapper {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	[vb-ui-radio-btn], button {
		float: left;

		&:first-child {
			margin-left: 0;
		}

		&:not(:first-child):not(:last-child) {
			margin-left: -1px;
			border-radius: 0;
		}

		&:first-child:not(:last-child) {
			.RoundedLeftShape(4px);
		}

		&:last-child:not(:first-child) {
			margin-left: -1px;
			.RoundedRightShape(4px);
		}
	}
}


:global(.vbUiRadioBtnActive) {
	.activeBtn;
}

.activeBtn {
	background: none;
	position: relative;

	.BtnWhiteActive;

	.glyphicons {
		color: @darkBlue;
	}

	&:hover {
		.BoxShadow(none) !important;
		background: linear-gradient(@color0Fade50, @color0Fade50);
	}
}

.multiSelectGroup {
	.multiSelectButton {
		border-radius: 0;
	}

	:first-child {
		border-radius: 3px 0 0 3px;
	}

	:last-child {
		border-radius: 0 3px 3px 0;
	}

	.multiSelectActive {
		color: @accentDarken20 !important;
		border-color: @accentDarken20Fade25 !important;
		background: @accentLighten45 !important;
		.BoxShadow(none);

		&:hover {
			.BoxShadow(none) !important;
			background: linear-gradient(@color0Fade50, @color0Fade50);
		}

		.glyphicons {
			color: @darkBlue;
		}
	}
}
