@import "global/variables";
@import "global/mixins";

/*
	_______________
	navbars

	mobile-hidden is hidden < 991
	mobile-visible is visible >992

	_______________
	everything else

	mobile-hidden is hidden < 768
	mobile-visible is visible >767

*/


.container-fluid {
	padding-right: 16px;
	padding-left: 16px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	margin-left: -16px;
	margin-right: -16px;
}

.form-group {
	position: relative;
}

.desktop-filters,
.mobile-hidden {
	display: none !important;
}

.flex-container .table-cell.mobile-visible {
	&:extend(.flexContainerStatic);
}

.col {
	&-fixed {
		&-40 {
			width: 40%;
		}
	}
}


@media(max-width: 767px){
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3,
	.col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
	.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8,
	.col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
	.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		padding-right: 16px;
		padding-left: 16px;
	}

	.form-horizontal .form-group {
		margin: 0;
	}

	.form-horizontal .form-group.apply-row-margin {
		margin: 0 -16px;
	}
}


@media (min-width: 500px) and (max-width: 767px) {
	.col-tablet-sm-6 {
		width: 50%;
		float: left;
		position: relative;
		min-height: 1px;
		padding-right: 16px;
		padding-left: 16px;
	}

	.col-tablet-sm-offset-3 {
		margin-left: 25%;
	}

}

@media (min-width: 768px) {

	.container-fluid {
		padding-right: 16px;
		padding-left: 16px;
	}
	.row {
		margin-left: -16px;
		margin-right: -16px;
	}

	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3,
	.col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
	.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8,
	.col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
	.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		padding-right: 16px;
		padding-left: 16px;
	}

	.form-horizontal .form-group {
		margin: 0 -16px;
	}

	.mobile-hidden {
		&:not(.ng-hide):not([hidden]) {
			&:not(.flex-navigation-bar) {
				display: block !important;
			}

			&.flex-navigation-bar {
				display: flex !important;
			}
		}

		&.table-cell:not(.ng-hide):not([hidden]) {
			display: table-cell !important;
		}
	}

	.flex-container .mobile-hidden.table-cell:not(.ng-hide):not([hidden]) {
		.flexContainerStatic !important;
		width: auto;
	}

	div .navbar .mobile-hidden.table-cell:not(.ng-hide):not([hidden]),
	.mobile-visible:not(.view-filters) {
		display: none !important;
	}

	span.mobile-hidden:not(.ng-hide):not(.flex-navigation-bar):not([hidden]) {
		display: inline !important;
	}
}

@media (min-width: 992px) {
	div .navbar .mobile-hidden {
		&:not(.ng-hide):not([hidden]) {
			display: block !important;
		}

		&.table-cell:not(.ng-hide):not([hidden]) {
			display: table-cell !important;
		}
	}

	.desktop-filters:not(.ng-hide):not([hidden]) {
		width: auto;
		&:extend(.flexContainerStatic);
	}

	.navbar .mobile-visible,
	.view-filters {
		display: none !important;
	}

	span.mobile-hidden:not(.ng-hide):not(.flex-navigation-bar):not([hidden]) {
		display: inline !important;
	}
}

@media (min-width: 1200px) {
	.container-fluid {
		padding-right: 32px;
		padding-left: 32px;
		margin-right: auto;
		margin-left: auto;
	}

	.home .row.toolbar {
		margin-left: -32px;
		margin-right: -32px;
		padding-left: 16px;
		padding-right: 16px;
	}
}

@media (min-width: 1800px){
	.col-xl {
		&-2,
		&-3,
		&-4,
		&-6,
		&-8 {
			width: 16.666666666667%;
			float: left;
			position: relative;
			min-height: 1px;
			padding-right: 16px;
			padding-left: 16px;
		}

		&-3 {
			width: 25%;
		}

		&-4 {
			 width: 33.33333333333%;
		 }

		&-6 {
			width: 50%;
		}

		&-8 {
			width: 66.66666666666%;
		}

		&-offset {
			&-2 {
				margin-left: 16.66666666667%;
			}

			&-3 {
				margin-left: 25%;
			}

			&-4 {
				margin-left: 33.33333333333%;
			}

			&-4-5 {
				margin-left: 37.5%;
			}

			&-5 {
				margin-left: 41.66666666667%;
			}

			&-6 {
				margin-left: 50%;
			}
		}
	}
}


