@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@triangleLen: 14px;

.dropdownMenu {
	composes: theme-primary from global;
	composes: theme-accent-border from global;
	margin-top: @triangleLen;
	padding: 10px;
	width: 280px;
	.RoundedShape(3px) !important;

	@media(min-width: @viewPortSmallWidth) {
		width: 600px;
	}
}

:global(.open) > .dropdownToggle {

	&:before, &:after {
		content: " ";
		position: absolute;
		left: @triangleLen + 5px;
		border-top: none;
		border-bottom: @triangleLen solid transparent;
		border-right: @triangleLen solid transparent;
		border-left: @triangleLen solid transparent;
		margin-left: -@triangleLen;
		background: transparent;
		z-index: 2000;
	}

	&:before {
		bottom: -@triangleLen;
		border-bottom-color: var(--accentColor, @accentColor)  !important;
	}

	&:after {
		bottom: -@triangleLen - 2px;
		border-bottom-color: var(--primaryColor, @accentColor)  !important;
	}
}

.toolbar {
	display: flex;
	justify-content: end;
	padding-right: 0 !important;
}
