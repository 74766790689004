@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

[uib-dropdown-menu].align-right {
	left: auto;
	right: 0;

	.BorderTopLeftRadius(4px);
	.BorderTopRightRadius(0);
}

/* bootstrap override of showing scrollbar always */
.dropdown-menu {
	.scrollable-area {
		overflow-y: auto !important;
	}

	> li > .dropdown-item {
		width: 100%;
		padding: 3px 20px;
		border: none;
		background: none;
		color: #333;
		text-align: left;
		line-height: 1.45;

		&:not(.themed-menu-item) {
			color: #333;
			&:hover {
				color: #262626;
				text-decoration: none;
				background-color: #f5f5f5;
			}
		}


	}
}

.dropdown-menu-right {
	left: auto !important;
}


.open {
	.dropdown-menu {
		&.width-100 {
			min-width: 110px;
		}
	}
}

.dropdown-toggle:focus {
	outline: 5px auto;

	// WebKit-specific. Other browsers will keep their default outline style.
	// (Initially tried to also force default via `outline: initial`,
	// but that seems to erroneously remove the outline in Firefox altogether.)
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.dropdown-list {
	&-item {
	.RoundedShape(0);

		/* some specificity overriding that has to be done for now */
		> .btn {
			border-color: @white !important;

			&:hover, &.active {
				color: @white !important;
				background-color: @accentColor !important;
			}
		}
	}

	&-link {
		text-align: left;
	}
}


button.dropdown-toggle::after {
	&, .dropup & {
		display: none;
	}
}

.dropup .dropdown-menu-right {
	inset: -4px 0 auto auto !important;
	transform: translateY(-100%) !important;
}

.dropdown-item:hover, .dropdown-item:focus {
	color: unset;
}
