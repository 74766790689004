@import "global/variables";
@import "global/mixins";

.card {
	margin-bottom: 20px;
}

.admin-content {
	.reports-account .row:first-child .report-section-header:first-child {
		border: none;
		.RoundedTopShape();
		.VerticalGradient(@white, @adminPanel);
	}
}
