@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.inputWrap {
	position: relative;
	width: 100%;

	ul {
		width: 100%;
		right: 0;
		margin: 0;
		padding: 0;
		font-size: @type16;
		border: 0;
		max-height: 400px;
		overflow-y: auto;
		list-style: none;
		.RoundedShape() !important;

		li > a {
			display: block;
			border-left: 0px;
			padding: 8px 16px;
			text-decoration: none !important;
		}
	}

	typeahead-container {
		display: block;
		width: 100%;
		margin-top: 10px !important;
	}

	input[vbUiTextInput] {
		font-size: @type16;
		height: 36px;
	}
}

.noRightBorderRadius {
	.BorderTopRightRadius(0) !important;
	.BorderBottomRightRadius(0) !important;
}

.noLeftBorderRadius {
	.BorderTopLeftRadius(0) !important;
	.BorderBottomLeftRadius(0) !important;
}

.dropdownMenu {
	position: absolute;
	width: 100%;
	margin-top: 2px;
	z-index: 1;
	background: @white;
	min-height: 150px;
	padding: 10px;
	color: @color1Lighten50;
	top: 43px;
	.RoundedShape();

	.fadeInOut {
		&:global(.ng-hide) {
			opacity: 0;
		}

		&:global(.ng-hide-add),
		&:global(.ng-hide-remove) {
			transition: all linear 0.5s;
		}
	}
}

.twoTierDropdownMenu {
	@media all and (min-width: @viewPortMedWidth) {
		width: auto;
		left: 0px;
		right: -48px;
		top: 50px;
	}
	@media all and (max-width: @viewPortMedWidth - 1) {
		top: 44px;
	}
}

.boldText {
	font-weight: bold;
}
