@import "global/variables";
@import "global/mixins";

.flex-container,
.file-list-row.flex-container,
.flex-row {
	.flexContainer (wrap; stretch; space-between);
	position: relative;
}

.flex-container > div,
.flex-row > div {
	&:extend(.flexContainerStatic);
}

.flex-no-shrink {
	.flexShrink(0) !important;
}

/*

	flex-table
	__________

	* keep the cells constrained to columns
	* no row wrapping

*/

.file-list-row {
	&.flex-container.flex-table {
		.flexFlow(row, wrap);

		/* force wrap */
		&.flex-wrap {
			.flexFlow(row, wrap);
		}
	}

	@media(max-width: 767px){
		.table-cell.flex-mobile-row {
			border-top: 0;
		}
	}
}

.flex-table {
	.table-cell {
		.flex(1);

		&.flex-fill,
		&.primary-cell {
			.flex(2) !important;

			&.flex-3 {
				.flexGrow(3);
			}
		}

		&.flex-mobile-row.primary-cell {
			border-top: 2px solid fade(@black, 20%);
			background: @blackFade05;
		}
	}

	> div.flex-2,
	> .flex-row > div.flex-2 {
		.flexGrow(2);
	}

	> div.flex-3,
	> .flex-row > div.flex-3 {
		.flexGrow(3);
	}
}

.flex-container {
	&.flex-nav {
		> .table-cell {
			white-space: nowrap;

			&:not(.ng-hide):not(.mobile-visible):not(.mobile-hidden):not(.desktop-filters) {
				&:extend(.flexContainerStatic);
				width: auto;
			}
		}

		&.filters {
			&.mobile-visible > .table-cell.dropdown {
				width: 100%;
			}

			> .table-cell.dropdown {
				width: 100%;
				margin-bottom: 5px;
			}
		}
	}

	.table-cell {
		width: auto;
	}
}

.flex-container {
	> div.flex-fill {
		.flexGrow(2) !important;
	}
}

.flex-row {
	&,
	&.file-list-row {
		.flexFlow(row);
	}

	&-full-width {
		.flexBasis(100%);

		pre {
			.whiteSpacePreWrap();
			width: 100%;
		}
	}
}

/* this is not abstracted, its specifically for device modal right now */

.flex-scroll {
	overflow: auto;
	position: absolute;
	top: 95px;
	bottom: 0;
	left: 0;
	right: 0;
}

@media(max-width: 767px) {

	/* flex-mobile-row turns the cell into a new table row at mobile width */
	.flex-mobile-row {
		.flexBasis(100%) !important;

		.btn-toolbar {
			padding-bottom: 8px;
		}

		+ .table-cell,
		+ div {
			padding-left: 16px !important;
		}
	}
}

@media (min-width: 768px) {
	.flex-container,
	.file-list-row.flex-container {
		.flexContainer(nowrap, stretch, space-between);
		position: relative;
	}

	.flex-mobile-row {

		.flex-table & {
			.flex(3);

			&.primary-cell {
				.flex(1);
				background: none;
				border-top: 1px solid fade(@black, 7.5%);

				&.flex-2 {
					.flex(2);
				}
			}
		}

		&.flex-3 {
			.flex(3);
		}

		.file-list-row &.table-cell {
			border-top: 1px solid fade(@black, 7.5%);
		}

		.btn-toolbar {
			padding-bottom: 0;
		}

		&.table-cell {
			.file-list-row & {
				border-top: 1px solid fade(@black, 7.5%);
			}

			.flex-table &.primary-cell {
				background: none;
				border-top: 1px solid fade(@black, 7.5%);
			}
		}
	}

	.flex-mobile-hide {
		&:extend(.flexDisplay);
	}
}

@media (max-width: 767px) {
	.flex-mobile-hide {
		display: none !important;
	}
}
