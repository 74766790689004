@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.event-nav { //webcast header + user notifications
	position: relative;
	min-height: 48px;
	border-bottom: 1px solid @borderColor;
	padding-top: 1px;

	.button-active-caret:after {
		border-top-color: var(--theme-accent);
	}

	.btn {
		height: 32px;
		line-height: 32px;
		.RoundedShape(3px);
		color: @color1MedText;
		padding: 0 10px !important;
		.BoxSizing(content-box);

		&-primary {
			color: @white;
		}
	}

	.glyphicons {
		margin-right: 3px;
	}

	&.event-breadcrumb-row {
		border-bottom: 1px solid @borderColor;
		padding-top: 10px;
		position: static;
	}

	/** have to make this important because of specificity issue with flexbox **/
	&-url-link {
		font-size: @type14;
		text-transform: none !important;
	}

	h1 {
		font-weight: normal;
	}

	.flex-navigation-bar-wrap {
		margin-left: 0;
	}

	.flex-navigation-bar {
		h1,h2,h3,h4 {
			color: inherit;
		}
	}

}

.button-active-caret {
	position: relative;

	&:after {
		border: solid transparent;
		border-width: 10px;
		content: " ";
		height: 0;
		left: 50%;
		margin-left: -10px;
		pointer-events: none;
		position: absolute;
		top: 100%;
		width: 0;

		@media all and (max-width: 600px) {
			border-color: transparent;
		}
	}
}


.btn-events {
	.button {
		&-active {
			position: relative;
			background: @accentColor !important;
			z-index: 2;

			&:active {
				background-color: @accentColor;
			}

			&:focus {
				outline: none;
			}


		}
	}
	&-dropdown {
		z-index: 2;
	}

	.btn {
		&-white {
			box-shadow: none;
		}
	}

	.dropdown-toggle {
		z-index: 3 !important;
	}
}
