@import "global/variables";
@import "global/mixins";

:root {
	--bs-secondary-color: #999;
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.admin .table th.bold-text,
.admin .table th.bold-text a,
.bold-text {
	font-weight: bold;
	color: @color1 !important;
}

.no-wrap {
	white-space: nowrap;
}

.carousel a {
	&:extend(.PointerCursor);
}

.label {
	.RoundedShape(4px);
}

h4 .circle {
	background: @color1LightText;
	color: fade(@white, 70%);
	text-shadow: none;
	margin-right: 5px;
	position: relative;
	top: -1px;
	font-size: 13px;
	padding-top: 1px;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-width: 4px 4px 0;
	border-style: solid;
	border-color: transparent;

	&,
	.nav &,
	.nav a:hover & {
		border-top-color: #969696;
	}

	.dropup & {
		border-bottom-color: @white;
		border-width: 0px 4px 4px;
	}

}

.centered-text-block {
	text-align: center;
	&:extend(.AbsoluteFill);

	&-content {
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 25%;
		margin-top: -36px !important;
		margin-bottom: 0;
		line-height: 48px;
	}
}

.group:not(.glyphicons) {
	&:after {
		visibility: hidden;
		display: block;
		content: "";
		clear: both;
		height: 0;
	}
}

.conflicted-bg {
	background-color: @warningBackground;
	padding: 16px 20px;

	label {
		color: @warningColor;
	}
}

.centered-copy {
	text-align: center;
	width: 80%;
	margin: 10% auto;

	p {
		font-size: 14px;
		margin-top: 20px;
	}
}

.glyphicons.chevron-down:before{
	content: "\E225";
	display: inline-block;
	transform: rotate(270deg);
}

.glyphicons.chevron-up:before{
	content: "\E225";
	display: inline-block;
	transform: rotate(90deg);
}
