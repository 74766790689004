@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

.authShell {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
	inset: 0px;

	.content {
		margin: auto;
		width: 50%;
		max-width: 36rem;
		padding: 20px;

		.centered {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.logoWrapper {
			padding-bottom: 30px;

			branding-logo img {
				height: 50px;
			}
		}

		.authMsg {
			font-size: @type16;
			margin-top: 30px;
		}

		.authCustomText {
			margin-top: 30px;
			max-height: 15vh;
			overflow-y: auto;
		}

		.heading {
			margin-top: 30px;
			text-align: center;
			font-weight: bold;

			.authMsg {
				font-weight: normal;
				margin-top: 0;
			}
		}

		.authBtn {
			width: 100%;
			font-weight: bold;
		}

		.authForm {
			input {
				font-size: @type16;

				&::placeholder {
					font-style: italic;
					font-size: @type14;
				}
			}

		}

		.pwValidation {
			text-align: right;
			font-size: @type12;
		}

	}
}

@media (max-width: 991px) {
	.authShell {
		.content {
			width: 70%;
		}
	}
}