@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.imagePreview {
	background: @adminPanel;
	border: 1px solid @borderColorLight;
	margin: @typeUnit1x 0;
	padding: @typeUnit1-2x;
	.RoundedShape();

	button {
		float: right;
		top: 5px;
		clear: both;
	}

	> img {
		max-height: 50px;
	}
}

.requiredCt {
	min-width: 150px;
}

.requiredIndicator:after {
	right: 1.2rem !important;
}

.fillContainer {
	height: 100%;
	width: 100%;
}

.uploadContainer {
	.uploadBtn {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 25px;

		span {
			height: unset;
			font-size: large;
		}
	}

	.themedUploadBtn {
		background-color: var(--theme-primary-overlay) !important;
	}
}

:global(.labelClass) {
	label {
		font-size: medium;
		padding-bottom: 10px;
	}
}

.v2FormGroup {
	margin-bottom: 0;
}

.thumbnailHelperTxt {
	margin-top: 16px;
}
