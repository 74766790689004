@import "global/variables";
@import "global/mixins";

/* TODO: this can pretty much all be replaced by flexbox for modern browsers,
   but prob still need this for IE9 support
   http://css-tricks.com/snippets/css/a-guide-to-flexbox/
*/
.table-display {
	display: table;
	width: 100%;
}

.table-row {
    display: table-row;
}

.table-cell {
	display: table-cell;
    width: 1px;

    &-no-wrap {
        white-space: nowrap;
    }

    /* there are subtle differences between 100% and auto widths for table-cells cross broswer */
    &-fill {
        width: auto;
    }

    &-search {
        text-align: right;
    }
}

.grid {
    &-background-light {
        background: @white;
    }

    > *[class ^="col-"] {
        margin-bottom: 30px;
    }

    .table-row, .dashboard-grid .table-row {
        display: block;
        padding: 0 15px;
    }
}

.list {
    .thumbnail {
        margin-right: 15px;
    }
}

.report-grid > div[class ^="col-"] {
	padding: 0;
	margin-bottom: 0;
}

.table-to-list td {
    display: none;
}

.panel + .panel {
	margin-top: 15px;
}

@media (min-width: 1410px){
    .container {
        max-width: 1290px;
    }
}

@media (min-width: 1800px){
    .container {
        max-width: 1760px;
    }
}

@media (min-width: 2100px){
    .container {
        max-width: 2400px;
    }
}

@media (min-width: 768px) {
    .grid .table-row {
        display: table-row;
        padding: 0;
        vertical-align: top;
    }

    .table-to-list td {
        display: table-cell;
    }
}
