@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.loadingContainer {
	left: 0;
	line-height: 1.5;
	overflow: hidden;
	//position: absolute;
	right: 0;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);

	&:not(:global(.box-inline)) {
		position: absolute;
	}

	&:global(.medium) {
		.msg {
			font-size: @type10;
			margin-bottom: 8px;
		}
	}

	&:global(.large) {

		.msg {
			font-size: @type21;
			margin-bottom: 10px;

			@media (max-width: @viewPortMobileDeviceWidth) {
				font-size: @type12;
			}
		}
	}
}

.iconWrap {
	position: relative;
	display: inline-block;
}

.icon {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&:before {
		display: none;
	}

	&:global(.xsmall) {
		font-size: @type12;
	}

	&:global(.medium) {
		font-size: @type18;
	}

	&:global(.large) {
		font-size: @type30;
	}

	&:global(.xsmall),
	&:global(.medium),
	&:global(.large) {
		display: inline-block;
		&:before {
			display: inline-block;
		}
	}

	&:global(.glyphicons) {
		top: calc(50% - 3px);
	}

}

.msg:empty {
	display: none;
}

.msg:not(:empty) {
	display: block;
	margin-top: 1rem;
	padding: 0 .5rem;
}
