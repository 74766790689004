@import "global/variables";
@import "global/mixins";
/*

To be replaced by flexbox
_________________________

This widget wraps standard bootstrap button groups and input groups so that they
can be used together in a responsive way.

can mix .table-cell, .btn-group and .input-group as needed.

Use table-cell-fill class to fill out/justify bar

*/

.combined-toolbar {
	display: table;
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;

	&.vertical-align-top .table-cell {
		vertical-align: top;
	}

	.btn {

		&.glyphicons {
			top: 0;
		}

		.svg-btn-icon {
			color: @color1;
			height: 14px;
			width: 10px;
			fill: @blackFade25;
			vertical-align: middle;
		}

		&.svg-btn-icon { /* SVG display breaks normal auto width behavior  */
			width: 36px;

			svg {
				color: @color1;
				height: 14px;
				width: 10px;
				fill: @blackFade25;
				vertical-align: middle;
			}
		}
	}

	.admin & .btn {
		float: none;
		padding: 6px;
	}

	.btn-transparent {
		color: @color1LightText;
	}

	h2 a {
		&:extend(.combined-toolbar .btn-transparent);

		&:hover,
		&:hover small {
			&:extend(.combined-toolbar a:hover);
		}
	}

	a:hover {
		color: @accentColor;
		text-decoration: none;
	}

	a.btn-admin:hover {
		color: @color1;
	}

	.inline-create-form & {
		padding: 0 10px;
	}

	> .btn-group,
	> .btn-toolbar,
	> .table-cell,
	> .input-group {
		vertical-align: top;

		&:first-child {
			padding-left: 0;
		}
	}

	> .btn-group,
	> .btn-toolbar {
		display: table-cell;
		width: 1%;
		white-space: nowrap;
	}

	> .table-cell:first-child.ng-hide + .table-cell,
	> .table-cell.shift-left {
		padding-left: 0;
	}

	.table-cell:last-child .btn-transparent:last-child {
		padding-right: 0;
	}

	> .table-cell {
		vertical-align: middle;
	}

	> .table-cell .btn-group {
		display: table;
		white-space: nowrap;

		> .btn {
			float: none;
			display: table-cell;
		}
	}

	> .table-cell-spacer > .btn-toolbar {
		display: inline-block;
	}

	> .table-cell label {
		margin-bottom: 0;
		vertical-align: middle;
		white-space: nowrap;
	}

	.input-group {
		> label {
			width: 1%;
			display: table-cell;
		}

		.margin-right-5 {
			padding-right: 5px;
		}
	}

	.table-cell-spacer {
		width: 10%;
	}

	select.form-control {
		font-size: 14px;
	}

	.ff-wrap-fix {
		right: 2px;
		width: auto;
		left: 10px;
	}

	h2,
	h3 {
		white-space: nowrap;
		margin: 0 15px 0 0;
	}

	&-spacer .spacer {
		width: 30px;
	}

	.admin & {
		.filter-input .input-group {
			width: 300px;

		  	input.form-control:focus {
				background: none;
				box-shadow: none;
		  	}
		}

		.glyphicons {
			line-height: 1;
		}
	}

	.table-cell {
		.input-group.search-input-group {
			.form-control {
				width: 200px;
				background: @whiteFade25;
				.BoxShadow(inset 1px 1px 2px @borderColor);
				border-right: 0;
			}

			.input-group-btn{
				background: @whiteFade25;
				.BoxShadow(inset -1px 1px 2px @borderColor);
				border: 1px solid fade(@black, 20%);
				border-left: none;
				.RoundedRightShape(3px);
			}

			.combined-toolbar .table-cell .input-group.search-input-group .input-group-btn .btn {
				padding: 7px 8px 6px 8px !important;
			}
		}

		&.text-align-right .btn-toolbar {
			float: right;
		}
	}

	&.responsive:not(.flex-container) {
		display: block;
	}

	.btn-toolbar {
		&:extend(.child-row .btn-toolbar);
	}

	.breadcrumb-row & > .table-cell {
		vertical-align: middle;
	}
}

.table-cell.group {
	white-space: nowrap;
}

.table-cell-sub-nav a {
	display: inline-block;
}

.child-row .btn-toolbar {
	white-space: nowrap;
	flex-wrap: nowrap;
}

.breadcrumb-row .combined-toolbar > .table-cell {
	vertical-align: middle;
}

@media(max-width: 767px) {
	.combined-toolbar {
		&.responsive {
			&:not(.flex-container) .table-cell:not(.mobile-hidden) {
				display: block;
				padding: 0 !important;
				margin-bottom: 8px;
			}

			.admin .device-item > & > .table-cell:first-child {
				padding: 0 0 0 55px !important;
			}

			.panel-sub-heading & .table-cell {
				margin-bottom: 0 !important;
			}

			.btn-group {
				padding: 0 !important;
				width: 100%;
				margin-bottom: 8px;
			}

			.table-cell {
				width: 100%;

				>.btn:not(.ng-hide),
				.btn-toolbar .btn:not(.ng-hide) {
					display: block !important;
					width: 100%;
					margin-bottom: 8px;
				}
			}

			&.flex-container .table-cell {
				width: auto;
			}

			.btn-toolbar {
				margin-left: 0;

				.btn {
					margin-left: 0;
				}

				&:before,
				&:after {
					content: none;
				}

				> .btn + .btn {
					margin-left: 0;
				}
			}

			.input-group .btn {
				display: inline-block !important;
				width: auto;
			}

			.dropdown .btn {
				text-align: left;
			}

			.btn.ng-hide,
			.btn.btn-back {
				display: none !important;
			}

			.admin & {
				.filter-input {
					width: 100%;
				}
			}
		}

		.admin & .filter-input .input-group,
		.admin .panel-sub-heading & .filter-input .input-group {
			width: 100%;
		}
	}

	.navbar-fixed-top .dropdown-menu .list-group-item {
		padding: 11px 15px 10px 30px; /* 36px height */
	}

    .view-options .btn-group > .btn{
	    width: 50%;
    }
}

@media(min-width: 768px) {
	.combined-toolbar {
		div &.responsive:not(.flex-container) {
			display: table;
		}

		> .btn-group,
		> .btn-toolbar,
		> .table-cell,
		> .input-group {
			padding-left: 10px;
			vertical-align: middle;
		}
	}
}
