@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.inputContainer {
	position: relative;
	background: white;
	display: flex;
	height: 40px;
	padding: 12px 10px;
	border-radius: 2px;
	margin-bottom: 4px;
	border: 1px solid @borderColor;

	.inputIcon {
		top: 2px;
		padding-left: 2px;
		color: grey;
	}

	input {
		border: none;
		color: black;
		margin-left: 4px;
		width: 100%;
	}

	input:focus-visible {
		outline: none;
	}
}

.inputContainer:focus-within {
	border: none;
}

.viewport {
	height: 20vh;
	:global(.cdk-virtual-scroll-content-wrapper) {
		position: inherit !important;
	}
}

.itemList {
	display: flex;
	flex-direction: column;
	width: 300px;
	padding-left: 0;
	margin: 0;
	list-style: none
}

.itemListItem {
	position: relative;
	display: block;
	padding: .75rem 1.25 rem;
	color: black;
	background-color: white;

	&:hover {
		cursor: pointer;
	}
}

.selectedOwnerContainer {
	display: flex;
	align-items: center;

	button {
		background-color: transparent;
		border: none;

		span {
			top: 2px;
		}
	}

	h5 {
		margin: 0;
	}
}

.annotation {
	font-size: smaller;
}

.profileContainer {
	display: flex;
}
